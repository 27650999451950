import axios from 'axios';

// 默认请求接口地址
// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL = '/dev-api';
const instance = axios.create({
	timeout: 5 * 1000
});

// 添加请求拦截器
instance.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.resolve(error);
	}
);

// 添加响应拦截器
instance.interceptors.response.use(
	function (response) {
    return response;
	},
	function (error) {
		// 对响应错误做点什么
		return Promise.resolve(error);
	}
);

export default {
	/**
	 * @param {String} url 接口地址
	 * @param {Object} data 参数
	 * @param {Boolean} isLoading 是否显示loading
	 */
	post(url, data = {}, options = {}) {
		return instance({
			url,
			method: 'post',
			data: data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			options
		});
	},
	/**
	 * @param {String} url 接口地址
	 * @param {Object} data 参数
	 * @param {Boolean} isLoading 是否显示loading
	 */
	postJSON(url, data, options = {}) {
		return instance({
			url,
			method: 'post',
			data: data,
			headers: {
				'Content-Type': 'application/json'
			},
			options
		});
	},
	/**
	 * @param {String} url 接口地址
	 * @param {Object} data 参数
	 * @param {Boolean} isLoading 是否显示loading
	 */
	get(url, data, options = {}) {
		return instance({
			url,
			method: 'get',
			params: data,
			options
		});
	},
};
