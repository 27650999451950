import request from '../utils/request';
const errorData = {
	code: 500,
	msg: "请求失败",
  data: {
    records:[]
  }
};
const errorOBJ = {
	code: 500,
	msg: "请求失败",
  data: {
  }
};
// 
const api = {
	login: params => {
		return new Promise((resolve) => {
			request.postJSON(`/webusers/login`, params).then((res) => {
				resolve(res.data);
			}).catch(() => {
				resolve(errorOBJ);
			}); 
		});
	},
	articleDetail: params => {
		return new Promise((resolve) => {
			request.get(`/articles/detail/${params}`, {userId: ''}).then((res) => {
				resolve(res.data);
			}).catch(() => {
				resolve(errorOBJ);
			}); 
		});
	},
	search: () => {
		return new Promise((resolve) => {
			request.get(`/webarticles/1/20`).then((res) => {
				resolve(res.data);
			}).catch(() => {
				resolve(errorData);
			}); 
		});
	},
	articleColumnId: params => {
		return new Promise((resolve) => {
			request.get(`/articles/${params}/1/20`).then((res) => {
				resolve(res.data);
			}).catch(() => {
				resolve(errorData);
			}); 
		});
  },
  createOrder: params => {
    return new Promise((resolve) => {
			request.postJSON(`/weborders/createNativeOrder`,params).then((res) => {
				resolve(res.data);
			}).catch(() => {
				resolve(errorData);
			}); 
		});
	},
	userOrderStatus: params => {
    return new Promise((resolve) => {
			request.get(`/weborders/userOrderStatus`,params).then((res) => {
				resolve(res.data);
			}).catch(() => {
				resolve(errorData);
			}); 
		});
  }
};

export default {
	...api
};